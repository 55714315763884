import { useState } from 'react';
import styled from 'styled-components/macro'; // Импортим именно /macro (для читаемых имен классов)
import { useSelector } from 'react-redux';
import { ButtonDark } from '../../../../ui/Button';

import { CampaignPublicType } from '../../../../project-types/campaign/types';
import { CampaignInviteAcceptType } from '../../../../project-types/campaign-join/types';
import { getCurrentSchool } from '../../../../feature/schoolsState';
import { SchoolPublicTypeLite } from '../../../../project-types/schools/types';
import { showPriceDigits } from '../../../../utils/formats';
import { formatDateDDMMMYYYY } from '../../../../ui/DateFormatMethods';
import CampaignDetails from './Components/CampaignDetails';
import StudentsForm from './Components/StudentsForm';
import DeviceTradeinBlock from './Components/DeviceTradeinBlock';
import BoostYourDonationBlock from './Components/BoostYourDonationBlock';

interface DonorInviteAcceptProps {
  campaign: CampaignPublicType;
  campaignJoin: Partial<CampaignInviteAcceptType>;
  setDonationAmout: (amount: number) => void;
  setCustomAmmount: (amount: number) => void;
  customAmmount: number | null;
  donationAmount?: number;
  onNext: (students: CampaignInviteAcceptType['students']) => void;
  currentSchool: SchoolPublicTypeLite;
  onTradeinClickHandler: () => void;
}

export default function DonorInviteAccept({
  campaign,
  currentSchool,
  donationAmount,
  setDonationAmout,
  setCustomAmmount,
  customAmmount,
  onNext,
  onTradeinClickHandler,
}: DonorInviteAcceptProps) {
  const [students, setStudents] = useState(['']);

  const onClickChangeDonationAmount = (amount: number) => () => {
    setDonationAmout(amount);
  };

  // console.log(campaign);

  const onChangeCustomDonationAmount = (e: any) => {
    console.log(e.target.value);
    setCustomAmmount(e.target.value);
    // setDonationAmout(e.target.value);
  };

  const onDecButtonClickHandler = () => setStudents((prev) => prev.filter((_, i) => i < prev.length - 1));
  const onIncButtonClickHandler = () => setStudents((prev) => [...prev, '']);

  const initialDonationAmount = campaign.initialDonation ? campaign.initialDonation : campaign.donationAmounts && campaign.donationAmounts[0] ? campaign.donationAmounts[0] : 0;
  const recurringDonationAmount = campaign.donationAmounts && campaign.donationAmounts[0] ? campaign.donationAmounts[0] : 0;

  const onNextClickHandler = () => {
    const studentsArr = campaign.isStudentsRefRequired && students.length ? students.map((s) => (s ? +s : 0)) : undefined;
    onNext(studentsArr);
  };

  return (
    <Content>
      <TitleBlock>
        <TitleContent>
          <Title>{campaign.name}</Title>
          <Text>
            {`You have been invited to join a ${currentSchool?.schoolName} donation scheme`}
          </Text>
        </TitleContent>
      </TitleBlock>
      <Container>
        <DescriptionBlock>
          <SubTitle>Campaign Description</SubTitle>
          <SmallText>{campaign.description}</SmallText>
        </DescriptionBlock>
        <CampaignDetailsBlock>
          <SubTitle>Campaign Details</SubTitle>
          <CampaignDetails campaign={campaign} initialDonationAmount={initialDonationAmount} recurringDonationAmount={recurringDonationAmount} />
        </CampaignDetailsBlock>
        <SubTitle>Please complete the following details to join the scheme</SubTitle>
        <StudentsForm students={students} onChangeStudents={setStudents} onDecButtonClickHandler={onDecButtonClickHandler} onIncButtonClickHandler={onIncButtonClickHandler} isIdRequired={typeof campaign.isStudentsRefRequired === 'boolean' ? campaign.isStudentsRefRequired : false} initialDonationAmount={initialDonationAmount} recurringDonationAmount={recurringDonationAmount} />
        <DeviceTradeinBlock onTradeinClickHandler={onTradeinClickHandler} />
        <BoostYourDonationBlock schoolCharityName={currentSchool?.charityName || ''} />
        <NextButton onClick={onNextClickHandler}>Next</NextButton>
      </Container>
    </Content>
  );
}

const Content = styled.main`
  width: 100%;
  border-top: 1px solid #E0E0E0;
`;

const Container = styled.div`
  width: 100%;
  max-width: 870px;
  margin: 0px auto;
  padding: 80px 0;
`;

const TitleBlock = styled.div`
  display: flex;
  background-color: #F5F8FB;
  justify-content: center;
  padding: 40px 0;
`;

const TitleContent = styled.div`
  width: 870px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Title = styled.h1`
  font-size: 30px;
`;

const SubTitle = styled.h2`
  font-size: 20px;
  font-weight: bold;
`;

const Text = styled.p`
  font-size: 20px;
  color: #6A7F94;
`;

const SmallText = styled(Text)`
  font-size: 14px;
  color: #102F54;
`;

const DescriptionBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-bottom: 70px;
`;

const CampaignDetailsBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 50px;
  max-width: 385px;
`;

const NextButton = styled(ButtonDark)`
  padding: 13px 100px;
  float: right;
`;
