import styled from 'styled-components/macro';
import { useHistory } from 'react-router';
import DropDown, { DropDownItem } from '../../../ui/DropDown';
import AccountPlaceholderImage from '../../../img/icons/accountImage.svg';
import ROUTER_CONFIG from '../../../router/router-config';
import { removeToken } from '../../../api';

interface AccountMenuProps {
  accountName: string;
  onDonationCampaignsClick: () => void;
  onDeviceTradeinClick: () => void;
  onAccountSettingsClick: () => void;
}

export default function AccountMenu({
  accountName, onDonationCampaignsClick, onDeviceTradeinClick, onAccountSettingsClick,
}: AccountMenuProps) {
  const { push } = useHistory();

  const onLogout = () => {
    push(ROUTER_CONFIG.DONOR_LOGIN.getPath());
    removeToken();
  };

  return (
    <Container>
      <img alt="" src={AccountPlaceholderImage} />
      <DropDown title={accountName}>
        <StyledText>your scheme support</StyledText>
        <DropDownItem title="Donation Campaigns" onClick={onDonationCampaignsClick} />
        <DropDownItem title="Device Trade-in" onClick={onDeviceTradeinClick} />
        <StyledText>settings</StyledText>
        <DropDownItem title="Account Settings" onClick={onAccountSettingsClick} />
        <DropDownItem title="Sign Out" onClick={onLogout} />
      </DropDown>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
`;

const StyledText = styled.span`
  font-size: 10px;
  color: #909DA8;
  text-transform: uppercase;
  padding: 10px;
`;
