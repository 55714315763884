import styled from 'styled-components/macro';
import { useFormik } from 'formik';
import { ButtonDark, ButtonLight } from '../../../ui/Button';
import Input from '../../../ui/forms/Input';

import { FormConfig, renderFormConfig } from '../../../utils/form';
import { SchoolLoginFields } from '../types';
import { UserRegisterType } from '../../../project-types/users/types';
import { signUpSchema } from '../../../project-types/users/schemas';

interface CreateSchoolFormTypes {
  onSignIn: (schoolFields: SchoolLoginFields) => void;
  onForgotPasswordClickHandler: () => void;
  onRegisterClickHandler?: () => void;
}

const InputStyled = styled(Input)`
  width: 100%;
`;

const formConfig: FormConfig = [
  {
    title: 'Email Address',
    name: 'email',
    component: InputStyled,
  },
  {
    title: 'Password',
    name: 'password',
    component: InputStyled,
    props: {
      type: 'password',
    },
  },
];

export default function LoginSchoolForm({ onSignIn, onForgotPasswordClickHandler, onRegisterClickHandler }: CreateSchoolFormTypes) {
  const formik = useFormik<UserRegisterType>({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: signUpSchema,
    onSubmit: (values) => {
      onSignIn(values);
    },
  });

  return (
    <FormStyled onSubmit={formik.handleSubmit}>
      {renderFormConfig(formConfig, formik)}
      <StyledSpan onClick={onForgotPasswordClickHandler}>Forgotten your password?</StyledSpan>
      <SubmitStyled type="submit">Sign In</SubmitStyled>
      {onRegisterClickHandler && (
        <>
          <Divider>Or</Divider>
          <StyledRegister onClick={onRegisterClickHandler}>Register</StyledRegister>
        </>
      )}
    </FormStyled>
  );
}

const FormStyled = styled.form`
  width: 100%;
  min-width: 375px;
  display: flex;
  flex-direction: column;
`;

const SubmitStyled = styled(ButtonDark)`
  padding: 13px 0;
  margin-top: 38px;
`;

const StyledSpan = styled.span`
  font-size: 16px;
  align-self: flex-end;
  color: #2682DE;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const Divider = styled.div`
  font-size: 14px;
  color: #6A7E95;
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  grid-column-gap: 5px;
  align-items: center;
  margin: 22px 0;

  &::before,
  &::after {
    content: "";
    display: block;
    height: 1px;
    background-color: #EBF2FA;
  }
`;

const StyledRegister = styled(ButtonLight)`
  padding: 13px 0;
`;
