import { DonorPublicFields, DonorPublicFieldsWithEmail } from '../../../../../project-types/donor/types';
import DataTable from '../../../../../ui/elements/Table';
import { DonorTableConfig, DonorTableRow } from '../../../types';

const tableConfig: DonorTableConfig = [
  {
    title: 'Full Name',
    dataIndex: 'fullName',
    key: 'fullName',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Gift Aid Registered',
    dataIndex: 'giftAid',
    key: 'giftAid',
  },
  {
    title: 'Student Reference(s)',
    dataIndex: 'studentReferences',
    key: 'studentReferences',
  },
  // {
  //   title: 'Donation Amount',
  //   dataIndex: 'amount',
  //   key: 'amount',
  // },
  {
    title: 'Successful Donations',
    dataIndex: 'numberOfDonations',
    key: 'numberOfDonations',
  },
  {
    title: 'Total Donations',
    dataIndex: 'total',
    key: 'total',
  },
  // {
  //   title: 'Gift Aid Amount',
  //   dataIndex: 'giftAidAmount',
  //   key: 'giftAidAmount',
  // },
];

interface DonorsTableProps {
  tableSource: Array<DonorTableRow>;
  onSelectDonor: (donorIds: Array<DonorPublicFieldsWithEmail['_id']>) => void
  selectedDonorsKeys: Array<DonorPublicFieldsWithEmail['_id']>;
}

export default function DonorsTable({ tableSource, onSelectDonor, selectedDonorsKeys }: DonorsTableProps) {
  return (
    <DataTable
      rowSelection={{
        type: 'checkbox',
        onChange: (ids) => {
          console.log(ids);
          onSelectDonor(ids as Array<DonorPublicFields['_id']>);
        },
        selectedRowKeys: selectedDonorsKeys,
      }}
      dataSource={tableSource}
      columns={tableConfig}
      style={{ borderTop: '2px solid #2682DE' }}
    />
  );
}
