import styled from 'styled-components/macro'; // Импортим именно /macro (для читаемых имен классов)
import { Tabs } from 'antd';
import CollectionTable from './Collections/CollectionTable';
import DonorsTable from './Donors/DonorsTable';
import { DonorCampaignsTableRow, DonorCollectionsTableRow } from '../types';
import { formatDateDDMMMYYYY } from '../../../ui/DateFormatMethods';
import { DonorPaymentStatsType, DonorCampaignSumRow } from '../../../project-types/donor-payment/types';
import { PaymentPublicType } from '../../../project-types/payment/types';
import CollectionPeriodComponent from '../../admin/all-collections/CollectionPeriodComponent';
import { serializeStatisticsData, serializeStatisticsPrice, showPriceDigits } from '../../../utils/formats';
import InfoTable from '../../../ui/elements/InfoTable';
import DonorsReports from './Donors/DonorsReports';

const { TabPane } = Tabs;

interface DonorDashboardProps {
  donorCampaignsList: Array<DonorCampaignSumRow>;
  onCancelDonationsClickHandler: () => void;
  donorCollections: Array<PaymentPublicType>;
  donorStatistics: DonorPaymentStatsType | null;
  currentPeriodMonth: number;
  onIncDateRange: () => void;
  onDecrDateRange: () => void;
  selectedCampaigns: Array<string>;
  onCampaignSelectHandler: (campaignIds: Array<string>) => void;
}

// Комопонент - функция
export default function DonorDashboard({
  donorCampaignsList, onCancelDonationsClickHandler, donorCollections, donorStatistics, currentPeriodMonth, onIncDateRange, onDecrDateRange, selectedCampaigns, onCampaignSelectHandler,
}: DonorDashboardProps) {
  const DonorStatData = [
    {
      title: 'Active donation campaigns',
      value: serializeStatisticsData(donorStatistics?.campaignsCount),
    },
    {
      title: 'Donations made',
      value: serializeStatisticsData(donorStatistics?.donationsCount),
    },
    {
      title: 'Donations sum',
      value: `£ ${serializeStatisticsPrice(donorStatistics?.donationsSum)}`,
    },
  ];

  const tableRowsData: Array<DonorCampaignsTableRow> = donorCampaignsList.map((campaign) => ({
    key: campaign._id,
    campaignId: campaign.campaignName,
    school: campaign.schoolName,
    activePeriod: campaign.activePeriod,
    collectionSum: `£ ${showPriceDigits(campaign.collectionSum)}`,
    cancelColumn: 'Cancel further donations',
    studentReferences: campaign.students && campaign.students.length ? campaign.students.join(', ') : '-',
  }));

  const collectionTableData: Array<DonorCollectionsTableRow> = donorCollections.map((collection) => ({
    key: collection._id,
    date: formatDateDDMMMYYYY(new Date(collection.postedDate)),
    totalAmount: `£ ${collection.amount}`,
    school: typeof collection.campaign !== 'string' && typeof collection.campaign.school !== 'string' ? collection.campaign.school.schoolName : '-',
    campaignId: typeof collection.campaign === 'string' ? collection.campaign : collection.campaign.name,
    balanceTransferStatement: '-',
    giftAidAmount: `£ ${showPriceDigits(collection.amount * 0.25)}`,
    giftAidStatement: '-',
    status: `${collection.status[0] + collection.status.slice(1).toLowerCase()}`,
  }));

  return (
    <Wrap>
      <Head>
        <Title>Donation Campaigns</Title>
        <InfoTable data={DonorStatData} />
      </Head>
      <Main>
        <TabsStyled defaultActiveKey="1">
          <TabsPaneStyled tab="Donations" key="1">
            <CollectionPeriodComponent monthNum={currentPeriodMonth} onIncDateRange={onIncDateRange} onDecrDateRange={onDecrDateRange} styles={{ marginBottom: '20px' }} />
            <CollectionTable tableData={collectionTableData} />
          </TabsPaneStyled>
          <TabsPaneStyled tab="Campaigns" key="2">
            <DonorsReports onCancelClickHandler={onCancelDonationsClickHandler} />
            <DonorsTable tableRows={tableRowsData} selectedCampaigns={selectedCampaigns} onCampaignSelectHandler={onCampaignSelectHandler} />
          </TabsPaneStyled>
        </TabsStyled>
      </Main>
    </Wrap>
  );
}

const Wrap = styled.main``;

const Head = styled.div``;

const Main = styled.div`

`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 30px;
  color: #102F54;
`;

const TabsStyled = styled(Tabs)`
  margin-top: 57px;
`;

const TabsPaneStyled = styled(TabPane)`
  & .ant-tabs-tabpane {
  }
  padding-top: 41px;
`;
